export const zaps = [
  {
    // YuzuSwap
    zapAddress: '0xa1Dfd44c90B7cE937FC450Fe19B691e72EE3a7D2',
    ammRouter: '0x250d48C5E78f1E85F7AB07FEC61E93ba703aE668',
    ammFactory: '0x5F50fDC22697591c1D7BfBE8021163Fc73513653',
    ammPairInitHash: '0x0bb8a7327a411245127096fe3de27e8b77c9f202a1451409e78b04984d70601a',
    lpProviderFee: 0.003,
  },
];
